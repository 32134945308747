import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Field, Form, Formik } from "formik";
import { CircularProgress, FormGroup, MenuItem, Grid, Typography, makeStyles } from "@material-ui/core";
import { object, string, email, array } from "yup";
import { useDispatch } from "react-redux";
import { getRoles } from "services/Api/api";
import { HttpErrorHandler } from "utils/ErrorHandlers/HttpErrorHandler";
import useApi from "hooks/useApi";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "#FAFAFA",
    },
    root: {
        flexGrow: 1,
        marginLeft: 30,
        marginRight: 20,
    },
    tabPanel: {
        backgroundColor: "#FAFAFA",
    },
    tab: {
        textTransform: "capitalize",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[6],
        padding: theme.spacing(4, 4, 3),
        borderRadius: 10
    },
}));
const AddAdminUserForm = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch();
    const [roles, setRoles] = React.useState([]);
    // const [rolesIsLoading, setRolesIsLoading] = React.useState(false);
    const [, addUserIsLoading, addUserHook] = useApi();
    const [, AdminUserRolesIsLoading, getAdminUserRoles] = useApi();
    const [users, getUserIsLoading, getUser] = useApi()
    const [username, setUsername] = useState(null)
    const [usageUsers, getUsageUserIsLoading, getUsageUsers] = useApi()
    const [initialValues, setInitialValues] = React.useState({
    });


    React.useEffect(async () => {

        await getUser({
            initialValues: [],
            module: 'accountDetails',
            method: 'getAccountUsers',
            apiData: {
                account_id: props.account_id
            }

        })

        const usageU = await getUsageUsers({
            initialValues: [],
            module: 'accountDetails',
            method: 'getUsageUsers',
            apiData: {
                account_id: props.account_id
            },
            returnResult:true

        })
        const usageUsersArr = []
        usageU.forEach(element => {
            usageUsersArr.push({ 'id': element.user_id,'email':element.email })
        });
        props.setUser(usageUsersArr)

    }, []);

    React.useEffect(() => {
            setInitialValues({
                selectedUsers: props.user
            });
        
    }, [props.user])
    console.log(initialValues)

    const handleSubmit = async (values, helpers) => {

        try {
            await addUserHook({
                module: "accountDetails",
                method: "saveUsageUsers",
                successToast: "Saved",
                apiData: {
                    data: {
                        selectedUsers: values.selectedUsers,

                    },
                    account_id: props.account_id
                },
                returnResult: true,
                throwError: true,

            });
            props.setUser(values.selectedUsers)
        } catch (e) {
            console.log(e);
            if (e.response.status === 403) {
                console.log(e.response?.data?.data)
                setUsername(e.response?.data?.data)
            }
            HttpErrorHandler(dispatch, e.response?.data?.message);
        }

    };

    if (!props.user && props.edit) {
        return <div></div>
    }
    console.log(initialValues)
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, helpers) => handleSubmit(values, helpers)}
            enableReinitialize={true}
        >
            {({ values, errors, isSubmitting, isValidating, touched, handleChange, setFieldValue }) => (
                <Grid container item xs={12}>
                    <Form style={{ width: '100%', height: '60vh' }} >
                        <Grid container xs={12} justifyContent="center" alignItems="center" style={{ height: '100%', backgroundColor: "white", borderRadius: 10, marginTop: 15, marginRight: 40 }} >
                            <Grid container xs={4} className={classes.paper} >
                                {(getUserIsLoading || getUsageUserIsLoading) ? (
                                    <Grid container xs={12} justifyContent="center">
                                        <CircularProgress size={30} />
                                    </Grid>
                                ) : (
                                    users && < Autocomplete

                                        options={users}
                                        name='selectedUsers'
                                        disableCloseOnSelect
                                        disableClearable
                                        limitTags={9}
                                        openOnFocus={true}
                                        getOptionLabel={(option) =>
                                            `${option?.email}`
                                        }
                                        getOptionSelected={(option, value) => {
                                            return value?.id === option?.id;
                                        }}
                                        value={values.selectedUsers}
                                        fullWidth
                                        onChange={(e, newValue, reason, detail) => {
                                            handleChange(e)
                                            console.log(newValue)
                                            console.log(e)
                                            setFieldValue('selectedUsers', newValue)

                                        }}
                                        size="small"
                                        multiple={true}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                size="small"
                                                label={'Selected Users'}
                                                variant="outlined"
                                            />
                                        )}
                                    />)
                                }
                                <Grid container item xs={12} justifyContent="center" style={{ marginTop: 20 }}>
                                    <Grid item xs={6}>
                                        <Button
                                            type="submit"
                                            disabled={isSubmitting || isValidating || addUserIsLoading}
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                        >
                                            {(isSubmitting || isValidating || addUserIsLoading) && <CircularProgress size={16} style={{ marginRight: 10 }} />}
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>


                </Grid>
            )
            }
        </Formik >
    );
};

export default AddAdminUserForm;
